import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from '@contentful/rich-text-types'

const WorkPage = (props) => {
    const { id, title, subtitle, videoLink, aspectRatio, blurb } = props.data.contentfulPortfolioPiece

    const Text = ({ children }) => <p>{children}</p>
    const richTextOptions = {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
            [INLINES.HYPERLINK]: (node, children) => {
                const { uri } = node.data
                return (
                    <a href={uri} target="_blank" rel="noreferrer">{children}</a>
                )
            }
        },
        renderText: text => {
            return text.split('\n').reduce((children, textSegment, index) => {
              return [...children, index > 0 && <br key={index} />, textSegment];
            }, []);
          },
    }

    const projectInfoAbove = () => (
        <div className="project-info withoutBlurb">
            <h1>{fullTitle()}</h1>
        </div>
    )

    const projectInfoBeside = (blurb) => (
        <div className="project-info withBlurb">
            <h1>{fullTitle()}</h1>
            {renderRichText(blurb, richTextOptions)}
        </div>
    )

    const fullTitle = () => {
        if (subtitle != null) {
            return title + " // " + subtitle
        } else {
            return title
        }
    }

    const convertLinkToEmbed = () => {
        if (videoLink.includes("vimeo")) {
            const regex = /(\/)(\d{5,10})/
            const vimeoID = videoLink.match(regex)[2]
            
            return (
                <iframe key={id} id="vimeo" src={"https://player.vimeo.com/video/" + vimeoID} width="100%" height="100%" title={title} frameBorder="0" webkitallowfullscreen="true" mozallowfullscreen="true" allowFullScreen allow="autoplay" data-ready="true"></iframe>
            )
        }
    
        if (videoLink.includes("youtu")) {
            const regex = /(be\/)(\S{11})|(watch\?v=)(\S{11})/
            const youtubeID = videoLink.match(regex)[2] ? videoLink.match(regex)[2] : videoLink.match(regex)[4]
    
            return (
                <iframe key={id} width="100%" height="100%" src={"https://www.youtube.com/embed/" + youtubeID} title={title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
            )
        }
    }

    return (
        <Layout>
            <div className={blurb == null ? "project-container full" : "project-container two-columns"}>
                {blurb == null && projectInfoAbove()}
                <div className="project-media" style={{aspectRatio: aspectRatio}}>
                    {convertLinkToEmbed()}
                </div>
                {blurb != null && projectInfoBeside(blurb)}
            </div>
        </Layout>
    )
}

export default WorkPage

export const query = graphql`
    query ($id: String) {
        contentfulPortfolioPiece(id: {eq: $id}) {
            id
            subtitle
            title
            videoLink
            aspectRatio
            blurb {
                raw
            }
        }
    }
`

export const Head = ({ data }) => {
    const { title, subtitle } = data.contentfulPortfolioPiece

    const fullTitle = () => {
        if (subtitle != null) {
            return title + " // " + subtitle
        } else {
            return title
        }
    }

    return (
        <>
            <title>{fullTitle()} - BO MIRHOSSENI</title>
        </>
    )
}
